<template>
  <div class="billing-transfer-item" v-if="filteredAccounts.length > 0">
    <div class="professional-title">
      <div class="columns">
        <div class="column centered">
          <strong class="hover-link" @click="showAccounts = !showAccounts">
            {{ data.name }}
          </strong>
          <span
            class="tooltip"
            data-tooltip="Existe(m) atendimento(s) sem valor de repasse!"
            v-if="hasInconsistencies">
            <fa-icon :icon="['fal', 'info-circle']" class="text-error" />
          </span>
        </div>
        <div class="column col-2 text-right centered">
          <small>Qtde.:</small> <strong>{{ quantity }}</strong>
        </div>
        <div class="column col-3 text-right centered">
          <strong :class="total < 0 ? 'text-error' : ''">{{ total | currency }}</strong>
        </div>
        <div class="column col-auto">
          <button class="btn btn-icon btn-action btn-primary btn-sm mr-1 tooltip"
                  data-tooltip="Acerto"
                  :disabled="quantity === 0"
                  @click="save">
            <fa-icon :icon="['fal', 'sack-dollar']"/>
          </button>
          <button class="btn btn-icon btn-action btn-gray btn-sm mr-1"
                  data-tooltip="Imprimir"
                  :class="printClass"
                  :disabled="printing || quantity === 0"
                  @click="openPrintModal">
            <fa-icon :icon="['fal', 'print']"/>
          </button>
          <button class="btn btn-icon btn-action btn-warning btn-sm"
                  :class="calculatorClass"
                  data-tooltip="Recalcular"
                  :disabled="calculating"
                  @click="recalculateTransfer">
            <fa-icon :icon="['fal', 'undo']"/>
          </button>
        </div>
      </div>
    </div>
    <div class="professional-card" v-if="showAccounts">
      <table class="table">
        <thead>
        <tr>
          <th style="width: 40px">
            <label class="form-checkbox text-primary">
              <input type="checkbox"
                     @change="selectAll"
                     :checked="getAccountSelected"
                     :indeterminate.prop="getAccountSelected === null">
              <i class="form-icon"/>
            </label>
          </th>
          <th style="width: 110px">Conta | Proced.</th>
          <th style="width: 100px">Fatura | Conta</th>
          <th style="width: 220px">Paciente | Status</th>
          <th>Procedimento | Convênio</th>
          <th class="text-right" style="width: 100px">Qtde. | Total</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in filteredAccounts" :key="i"
            :class="item.isReport ? 'c-hand' : ''"
            :style="item.isReport ? 'background-color:aliceblue' : ''"
            @click="openMedicalReport(item.isReport)">
          <td>
            <div v-if="item.isReport">
              <div v-if="modalReport.loading" class="loading" />
            </div>
            <label class="form-checkbox" v-else>
              <input type="checkbox" v-model="item.selected"
                     @change="selectAccount(item, !item.selected)">
              <i class="form-icon"></i>
            </label>
          </td>
          <td>
            <div v-if="item.isReport">
              <span>Laudos assinados</span><br>
              <strong class="text-info">Finalizados</strong>
            </div>
            <div v-else>
              <span>{{ item.accountDate | date('DD/MM/YY HH:mm') }}</span><br>
              <span>{{ item.date | date('DD/MM/YY HH:mm') }}</span>
            </div>
          </td>
          <td>
            <span :class="!item.isReport ? 'hover-link tooltip tooltip-right' : ''"
                  :data-tooltip="!item.isReport ? 'Selecionar as faturas' : ''"
                  @click="selectInvoice(item, item.selected)">
              {{ item.invoiceCode }}
            </span><br>
            <span :class="!item.isReport ? 'hover-link tooltip tooltip-right' : ''"
                  :data-tooltip="!item.isReport ? 'Selecionar as contas' : ''"
                  @click="selectAccount(item, item.selected)">
              {{ item.code }}
            </span>
          </td>
          <td class="text-ellipsis" style="max-width: 220px">
            <span>{{ item.isReport ? '-' : item.patient.name }}</span><br>
            <span v-if="item.isReport">-</span>
            <strong class="text-info" v-else>
              {{ status.getName(item.status) }}
            </strong>
          </td>
          <td>
            <div>
              <span>{{ item.expenseName }}</span>
              <span class="text-error"
                    v-if="item.consultationType === 'return'"> (Retorno)</span><br>
              <span>{{ item.insurance.customName }}</span>
            </div>
          </td>
          <td class="text-right">
            <strong>{{ item.quantity | number }}</strong><br>
            <strong :class="item.total < 0.01 ? 'text-error' : ''">
              {{ item.total | currency }}
            </strong>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <payment-modal
      v-if="showPaymentModal"
      :show="showPaymentModal"
      :data="data"
      :filter="filter"
      @close="showPaymentModal = false"
    />
    <dx-modal title="Laudos" size="lg"
              v-model="modalReport.show" id="modal-transfer-report">
      <table class="table">
        <thead>
        <tr>
          <th>Data</th>
          <th>Paciente</th>
          <th>Convênio</th>
          <th>Exame</th>
          <th class="text-center">Qtde.</th>
          <th class="text-right">Valor</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="(item, i) in modalReport.items" :key="i">
          <td>{{ item.date | date }}</td>
          <td>{{ item.patient.name }}</td>
          <td>{{ item.insurance.name }}</td>
          <td>
            <small>{{ item.expense.name }}</small>
          </td>
          <td class="text-center">{{ item.expense.quantity }}</td>
          <td class="text-right">{{ item.expense.value.total | currency }}</td>
        </tr>
        </tbody>
      </table>
      <div class="float-right mt-2 mr-2">
        Total: <strong class="text-primary">{{ modalReport.total | currency }}</strong>
      </div>
      <template slot="footer">
<!--        <button class="btn btn-primary btn-icon-left mr-1">-->
<!--          <fa-icon :icon="['fal', 'print']"/>-->
<!--          Imprimir-->
<!--        </button>-->
        <button class="btn btn-icon-left" @click="modalReport.show = false">
          Fechar
        </button>
      </template>
    </dx-modal>
    <dx-modal title="Relatório de repasses" size="sm"
              v-model="modalPrint.show" id="modal-transfer-print">
      <div class="item-group">
        <div class="form-group">
          <label class="form-radio form-inline mr-2">
            <input type="radio" name="print-type"
                   v-model="modalPrint.type" value="short">
            <i class="form-icon"></i>Recibo resumido
          </label>
          <label class="form-radio form-inline mr-2">
            <input type="radio" name="print-type"
                   v-model="modalPrint.type" value="detailed">
            <i class="form-icon"></i>Recibo detalhado
          </label>
        </div>
      </div>
      <div class="columns">
        <div class="column col-4 form-group">
          <label class="form-label">Total</label>
          <dx-input-number id="current-value"
                           v-model="total"
                           class="form-input text-right text-bold"
                           :precision="2"
                           readonly
          />
        </div>
        <div class="column col-4 form-group">
          <label class="form-label">Acréscimo</label>
          <dx-input-number id="current-value"
                           v-model="modalPrint.interest"
                           class="form-input text-right"
                           maxlength="8"
                           :precision="2"
          />
        </div>
        <div class="column col-4 form-group"
             :class="{'has-error': $v.modalPrint.discount.$error}">
          <label class="form-label">Desconto</label>
          <dx-input-number id="current-value"
                           v-model="modalPrint.discount"
                           @blur="$v.modalPrint.discount.$touch()"
                           maxlength="8"
                           class="form-input text-right"
                           :precision="2"
          />
          <template v-if="$v.modalPrint.discount.$error">
            <div class="form-input-hint"
                 v-if="!$v.modalPrint.discount.maxValue">
              Valor inválido
            </div>
          </template>
        </div>
      </div>
      <template slot="footer">
        <button class="btn btn-primary btn-icon-left mr-1" @click="print">
          <fa-icon :icon="['fal', 'print']"/>
          Imprimir
        </button>
        <button class="btn btn-icon-left" @click="modalPrint.show = false">
          Fechar
        </button>
      </template>
    </dx-modal>
  </div>
</template>

<script>
import { PRINT_BILLING_TRANSFER, PAY_BILLING_TRANSFER } from '@/data/actions/modules/financial';
import { maxValue } from 'vuelidate/lib/validators';
import * as status from 'src/data/invoice-statuses';
import PaymentModal from './Payment.vue';

export default {
  props: {
    filter: {
      dateType: {
        type: String,
      },
      allValues: {
        type: Boolean,
      },
      startDate: {
        type: Date,
      },
      endDate: {
        type: Date,
      },
    },
    data: {
      id: {
        type: String,
      },
      name: {
        type: String,
      },
      accounts: {
        type: Array,
      },
    },
  },
  components: {
    PaymentModal,
  },
  data() {
    return {
      status,
      calculating: false,
      printing: false,
      loading: false,
      showAccounts: false,
      showPaymentModal: false,
      modalPrint: {
        show: false,
        type: 'short',
        interest: '',
        discount: '',
      },
      modalReport: {
        show: false,
        loading: false,
        total: 0,
        items: [],
      },
    };
  },
  validations() {
    return {
      modalPrint: {
        discount: {
          maxValue: maxValue(this.total),
        },
      },
    };
  },
  computed: {
    filteredAccounts() {
      if (!this.filter.allValues) {
        return this.data.accounts.filter(({ total }) => total !== 0);
      }
      return this.data.accounts;
    },
    quantity() {
      return this.data.accounts.filter(item => item.selected).length;
    },
    total() {
      return this.data.accounts
        .filter(item => item.selected)
        .reduce((a, b) => a + b.total, 0);
    },
    hasInconsistencies() {
      return this.data.accounts
        .some(item => item.selected
          && item.total === 0
          && item.consultationType !== 'return');
    },
    canAccessPrintTransfer() {
      if (PRINT_BILLING_TRANSFER) {
        return this.$can(PRINT_BILLING_TRANSFER);
      }
      return true;
    },
    canAccessPayTransfer() {
      if (PAY_BILLING_TRANSFER) {
        return this.$can(PAY_BILLING_TRANSFER);
      }
      return true;
    },
    getAccountSelected() {
      if (!this.data.accounts || this.data.accounts.length === 0) {
        return false;
      }

      const selected = this.data.accounts.filter(item => item.selected);
      if (selected.length === 0) {
        return false;
      }
      if (selected.length === this.data.accounts.length) {
        return true;
      }
      return null;
    },
    printClass() {
      if (this.printing) {
        return 'loading';
      }
      return 'tooltip';
    },
    calculatorClass() {
      if (this.calculating) {
        return 'loading';
      }
      return 'tooltip';
    },
  },
  methods: {
    save() {
      if (!this.canAccessPayTransfer) {
        this.$toast.show('Acesso não autorizado!', { type: 'error' });
        return;
      }

      if (this.quantity === 0) {
        return;
      }

      this.showPaymentModal = true;
    },
    openPrintModal() {
      if (!this.canAccessPrintTransfer) {
        this.$toast.show('Acesso não autorizado!', { type: 'error' });
        return;
      }
      if (this.data.accounts.length === 0) {
        return;
      }

      this.modalPrint.type = 'short';
      this.modalPrint.interest = '';
      this.modalPrint.discount = '';

      this.modalPrint.show = true;
    },
    openMedicalReport(isReport) {
      if (this.modalReport.loading || !isReport) {
        return;
      }
      this.modalReport.total = 0;
      this.modalReport.loading = true;

      const params = {
        startDate: this.filter.startDate,
        endDate: this.filter.endDate,
      };

      this.$http.get(`/professional-transfer/${this.data.id}/medical-reports`, { params })
        .then(({ data }) => {
          this.modalReport.items = data.items;
          this.modalReport.total = data.items.reduce((a, b) => a + b.expense.value.total, 0);
          this.modalReport.show = true;
        })
        .catch(() => {
          this.$toast.error('Ocorreu um erro. Tente novamente!');
        })
        .finally(() => {
          this.modalReport.loading = false;
        });
    },
    recalculateTransfer() {
      if (this.data.accounts.length === 0) {
        return;
      }

      const yesAction = (close) => {
        if (this.loading || this.calculating) return;

        this.calculating = true;

        close();
        const data = {
          invoiceIds: this.data.accounts.reduce((results, item) => {
            const found = results.find(id => id === item.invoiceId);
            if (!found) {
              results.push(item.invoiceId);
            }
            return results;
          }, []),
        };

        this.$http
          .put('/recalculate-accounts', data)
          .then(() => {
            this.$emit('update');
            this.$toast.show('Repasse atualizado com sucesso!');
          })
          .catch((e) => {
            if (e.response
              && e.response.data
              && e.response.data.message) {
              this.$toast.show(e.response.data.message, { type: 'error' });
            } else {
              this.$toast.show('Ocorreu um erro ao atualizar os valores!', { type: 'error' });
            }
          })
          .finally(() => {
            this.calculating = false;
          });
      };

      this.$dialog.show('', {
        html:
          '<div class="text-center">'
          + '<div class="h6 text-center mb-2">Atenção!</div>'
          + '<strong class="text-error">Apenas as faturas não finalizadas serão atualizadas!</strong>'
          + '<div class="mt-2">Deseja realmente continuar?</div>'
          + '</div>',
        buttons: [
          {
            label: 'Não',
            classes: '',
          }, {
            label: 'Sim',
            classes: 'btn-primary btn-error ml-2',
            click: (close) => {
              this.$nextTick(() => {
                yesAction(close);
              });
            },
          },
        ],
      });
    },
    print() {
      this.$v.modalPrint.$touch();
      if (this.$v.modalPrint.$error) {
        return null;
      }

      this.modalPrint.show = false;
      this.printing = true;

      const params = {
        professionalId: this.data.id,
        accounts: this.data.accounts
          .filter(item => item.selected && !item.isReport)
          .map(item => ({
            itemId: item.id,
            accountId: item.accountId,
          })),
        type: this.modalPrint.type,
        dateType: this.filter.dateType,
        startDate: this.filter.startDate,
        endDate: this.filter.endDate,
        allValues: this.filter.allValues,
      };

      if (this.modalPrint.interest) {
        params.interest = this.modalPrint.interest;
      }

      if (this.modalPrint.discount) {
        params.discount = this.modalPrint.discount;
      }

      return this.$file
        .print(`/professional-transfer/${this.data.id}/print`, params, { method: 'POST' })
        .catch(() => {})
        .then(() => {
          this.printing = false;
        });
    },
    selectAll(e) {
      this.data.accounts
        .filter(({ isReport }) => !isReport)
        .forEach((item) => {
          item.selected = e.target.checked;
        });
    },
    selectAccount(item, selected) {
      if (item.isReport) {
        return;
      }
      this.data.accounts
        .filter(account => account.code === item.code)
        .forEach((account) => {
          account.selected = !selected;
          return account;
        });
    },
    selectInvoice(item, selected) {
      if (item.isReport) {
        return;
      }
      this.data.accounts
        .filter(account => account.invoiceCode === item.invoiceCode)
        .forEach((account) => {
          account.selected = !selected;
          return account;
        });
    },
  },
};
</script>

<style lang="scss">
@import "../../../../assets/scss/variables";

.billing-transfer-item {
  border: $border-width solid $border-color;
  border-radius: $border-radius;
  margin: $layout-spacing-xl 0;

  .professional-title {
    background-color: $gray-color-ultra-light;
    padding: $layout-spacing-sm;
  }

  .professional-card {
    .table {
      th, td {
        //font-weight: 500;
        font-size: 0.5rem;
      }
    }
  }
}
#modal-transfer-print {
  .item-group {
    background-color: $gray-color-ultra-light;
    border: $border-width solid $border-color;
    border-radius: $border-radius;
    margin-bottom: $layout-spacing;
    padding: $layout-spacing-sm $layout-spacing-lg;
  }
}
</style>
